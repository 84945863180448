.home__hero-section {
  color: #fff;
  padding: 160px 0;
}

.home__hero-row {
  align-items: center;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrapper {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
}

.top-line {
  color: #b82f24;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.heading {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #f7f8fa;
}

.dark {
  color: #1c2237;
}

.darkBg {
  background-color: #1c2237;
}

.home__hero-subtitle {
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
}

.home__hero-img-wrapper {
  max-width: 555px;
}

.home__hero-img {
  max-width: 95%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .home__hero-text-wrapper {
    padding-bottom: 65px;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.courses_head {
  background-image: linear-gradient(rgba(16, 15, 15, 0.397), #1c2237),
    url("../Courses/images/web_dev.jpg");
  background-size: cover;
  background-position: center;
  /* background-position: top; */
  min-height: 70vh;
  min-width: 100%;
  text-align: center;
  color: #fff;
  /* line-height: 20px; */
  z-index: 0;
  /* text-transform: uppercase; */
  font-size: 20px;
  float: none;
  margin-top: 0;
}

/* .intro-text {
 
  padding-top: 100px;
  font-size: 3.5rem;

} */

.dev {
  display: grid;
  max-width: 1300px;
  grid-gap: 40px;
  margin: 0 auto;
  line-height: 30px;
  padding: 15px 50px;
}

p {
  text-align: justify;
  font-size: 20px;
}

.deve {
  padding-top: 20px;
  display: grid;
  justify-items: center;
  align-items: center;
}

@media screen and (min-width: 789px) {
  .dev,
  .deve {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

ul.dev_li {
  list-style: none;
  padding-left: 5px;
}

/* PYTHON CSS */

.python_head {
  background-size: cover;
  background-position: center;
  max-height: 40vh;
  object-fit: fill;
  width: 100%;
}
.py_head {
  color: white;
  font-size: 45px;
}
.why {
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.first_image {
  width: 150px;
  justify-self: center;
  align-items: center;
  justify-items: center;
}
@media screen and (min-width: 789px) {
  .why {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    max-width: 1100px;
    padding-top: 50px;
    margin: 0 auto;
  }
}

.whyh1,
.whyp {
  padding: 5px;
  justify-self: center;
  text-align: justify;
}

.comp_outer {
  background-color: #1c2237;
}

.companies {
  padding: 150px;

  color: #fff;
}

@media screen and (min-width: 789px) {
  .comp {
    font-size: 20px;
  }
  .companies {
    display: grid;
    grid-template-columns: 40% 60%;
    justify-items: center;
    align-items: center;
    max-width: 1100px;
    padding-top: 100px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 789px) {
  .companies_icons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    padding: 20px;
  }
}

.learn_outer {
  background-color: #ddd;
}
.whylearn {
  text-align: center;
}

.learn {
  padding: 30px;

  color: #000;
}

@media screen and (min-width: 789px) {
  .learn_text {
    font-size: 20px;
  }
  .learn {
    display: grid;
    justify-items: center;
    align-items: center;
    max-width: 1100px;
    padding-top: 100px;
    padding-left: 100px;
    padding-right: 100px;
    margin: 0 auto;
  }
}

.range {
  margin-top: 80px;
}
.ran {
  padding: 10px 0px;
}
@media screen and (min-width: 789px) {
  .range {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    padding: 20px;
    grid-gap: 100px;
    margin-top: 100px;
  }
}

.common {
  background-color: #ddd;
}

.uses {
  max-width: 1300px;
  align-items: center;
  justify-items: center;
  display: grid;
  grid-gap: 20px;
  margin: auto;
}

.uses_details {
  font-size: 1.1rem;
  padding: 10px;
  font-size: 20px;
}

.uses_img_pic {
  border-radius: 50%;
}

.uses_img {
  padding-bottom: 50px;
}

.uses_text {
  margin: 50px;
  text-align: center;
  justify-self: center;
  font-size: 25px;
}

@media screen and (min-width: 789px) {
  .uses_img {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 35px;

    margin: 0 auto;
    align-items: center;
    justify-items: center;
  }
}

.features {
  background-color: #1c2237;
  color: #fff;
  padding: 20px;
}
.features_container {
  max-width: 1300px;
  margin: auto;
}
.features_head {
  padding: 50px;
  text-align: center;
}
.feature {
  margin: 25px;
}
.feature > h5 {
  color: #b82f24;
}

@media screen and (min-width: 789px) {
  .features_of_python {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    padding: 50px;
  }
}

.python_graphic {
  background: #1c2237;
}
.python_graphic_illustration {
  display: block;
  margin: auto;
  background-size: cover;
}

.approach {
  max-width: 1300px;
  margin: auto;
}

.approach_head {
  text-align: center;
}

.approach_head > h2 {
  padding-top: 50px;
  font-size: 30px;
}

.approach_head > h3,
h6 {
  padding: 15px;
  font-size: 1.2rem;
}

.approach_details {
  display: grid;
  justify-items: center;
  align-items: center;
}

.tech_topics {
  text-align: center;
}

.tech_topics > h4,
h5 {
  padding: 15px;
}
.tech_topics > h6 {
  max-width: 400px;
  line-height: 20px;
  word-spacing: 10px;
  font-size: 1rem;
}

.lastt {
  margin-bottom: 40px;
}
@media screen and (min-width: 789px) {
  .approach_head > h3,
  h6 {
    padding: 20px;
    font-size: 1.5rem;
  }

  .approach_details {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
  }
  .tech_topics {
    max-width: 400px;
    line-height: 30px;
    word-spacing: 10px;
  }
}

.fixed {
  position: fixed !important;
  top: 70px;
}

@media screen and (min-width: 789px) {
  .fixed {
    position: fixed !important;
    top: 70px;
    max-width: 1300px;
    text-align: center;
    justify-items: center;
    display: grid;
    right: 140px;
    left: 140px;
    z-index: 1;
    border-radius: 15px;
  }
}

.navigation {
  width: 100%;
  background-color: #272626;
  height: 75px;
  color: #fff;
}

.navigation ul {
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;
}

.navigation ul li {
  display: inline-block;
  margin: 0 0 0 30px;
  padding: 0;
}

.navigation ul li.active a {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: inherit;
  color: #fff;
}

.navigation ul li.web {
  border-bottom-color: #fff;
}

.navigation ul li.python {
  border-bottom-color: #fff;
}

/* .navigation ul li.orange {
  border-bottom-color: #fc4c02;
}

.navigation ul li.yellow {
  border-bottom-color: #f3d54e;
} */

.navigation ul li a:link,
.navigation ul li a:visited,
.navigation ul li a:active,
.navigation ul li a:focus {
  color: #fff;
  text-decoration: none;
  padding-bottom: 10px;
  font-weight: bold;
}

.navigation ul li a:hover {
  color: #b82f24;
}

.front_img {
  background-image: linear-gradient(rgba(24, 20, 20, 0.611), #0000029e),
    url("../Courses/images/dashboard.jpg");
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
}

.full_img {
  background-image: linear-gradient(rgba(24, 20, 20, 0.611), #0000029e),
    url("../Courses/images/class.jpg");
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
}
.reactt_img {
  background-image: linear-gradient(rgba(24, 20, 20, 0.611), #0000029e),
    url("../Courses/images/fourth.jpg");
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
}
.py_img {
  background-image: linear-gradient(rgba(24, 20, 20, 0.611), #0000029e),
    url("../Courses/images/fifth.jpg");
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
}
