

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Nunito', sans-serif;
  color: #384047;
}
.table{
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
}
table {
  width: 100%;
  margin: 10px auto;
}



thead th {
  font-weight: 400;
  background: #e38f93;
  color: #FFF;
}

tr {
  background: #ffffff;
  border-bottom: 3px solid rgb(17, 15, 15);
  margin-bottom: 5px;
}

tr:nth-child(even) {
  background: #ffffff;
}

th, td {
  text-align: left;
  padding: 20px;
  font-weight: 300;
}

tfoot tr {
  background: none;
}

tfoot td {
  padding: 10px 2px;
  font-size: 0.8em;
  font-style: italic;
  color: #df696d;
}
