/* body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.off-screen {
  transform: translateX(-100%); /* Or translateY(-100%) for vertical movement */
  transition: transform 0.5s ease-in-out;
}

.on-screen {
  transform: translateX(0);
}
.marquee {
  width: 100%;
  background: rgb(123, 8, 39);
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  color: #fff;
}

.marquee div {
  font-size: 1rem;
  font-family: verdana;
  padding-left: 100%;
  display: inline-block;
  animation: animate 30s linear infinite;
}
.marquee:hover div{
  animation-play-state: paused;
}

@keyframes animate {
  100% {
    transform: translate(-100%, 0);
  }
}