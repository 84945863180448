@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;&display=swap");

.btn-primary {
  border-radius: 8px;
  border: none;
  background-color: #ff0000;
  color: #ffffff;
}

.btn-primary:hover {
  background-color: #eb0a0a;
}
.Signin {
  max-width: 500px !important;
  margin-top: 150px !important;
}
.card-title {
  font-size: 30px;
  margin-bottom: 15px;
}
