.jumbotro {
  background-image: url(../Courses/images/slide1.jpg);
  background-size: cover;
  height: 500px;
  max-height: 700px;
  background-repeat: no-repeat;

  padding: 20px !important;
  text-align: center !important;
}

.headings {
  text-align: center;
  font-size: 60px;
  font-style: italic;
  font-family: "Courier New", Courier, monospace;
  color: #ffffff !important;
}
.containertable {
  text-align: center;
  font-size: 25px;
}

.heading2 {
  font-size: 26px;
  margin: 20px 0;
  text-align: center;
}

.table-row {
  border-radius: 3px;
  padding: 25px 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.table-row2 {
  border-radius: 3px;
  padding: 25px 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.table-header {
  background-color: #1c2237;
  font-size: 14px;
  padding-top: 20px;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  display: flex;
  color: #ffffff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  align-items: center;
  height: 50px;
}
.table-row {
  background-color: #ffffff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}
.table-row2 {
  background-color: #1c2237;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  color: #ffffff;
}
.col-1 {
  flex-basis: 20%;
}
.col-2 {
  flex-basis: 30%;
}
.col-3 {
  flex-basis: 25%;
}
.col-4 {
  flex-basis: 25%;
}

@media all and (max-width: 767px) {
  .table-header {
    display: none;
  }
  .jumbotro {
    background-image: url(../Courses/images/slide1.jpg);
    background-size: cover;
    height: 100%;
    max-height: 900px;
    background-repeat: no-repeat;

    padding: 20px !important;
    text-align: center !important;
  }
  .headings {
    text-align: center;
    font-size: 50px;
    font-style: italic;
    font-family: "Courier New", Courier, monospace;
  }

  .table-row {
    display: block;
  }
  .table-row2 {
    display: block;
  }
  .col {
    flex-basis: 100%;
  }
  .col {
    display: flex;
    padding: 10px 0;
  }
}
.webcontainer {
  padding-top: 10px;
}
.coding_Bootcamp {
  display: flex;
  justify-content: center;
  height: 70vh;
  align-items: center;
  padding-top: 50px;
}
.flex {
  width: 50%;
  display: flex;

  padding-top: 5px;
  padding-left: 5px;
}
.flex2 {
  padding-left: 20px;
}
.cols-1 {
  text-align: center;

  z-index: 10;
}

.grad {
  background-image: url("../Courses/images/web1.jpg");
  background-size: cover;
}
.responsive-table {
  max-width: 1300px;
  margin: 0 auto;
  padding-top: 20px;
}
