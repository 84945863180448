.timetable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .timetable th,
  .timetable td {
  
    text-align: left;
  }
  
  .timetable th {
    background-color: #333;
    font-size: 17px; /* Adjust the font size as desired */
    font-weight: 700; /* Increase the font weight for boldness */
  }
  
  .timetable tr.even {
    background-color: #f2f2f2;
  }
  
  .timetable tr.odd {
    background-color: #fff;
  }
  
  .timetable td {
    font-size: 16px;
    font-weight: 700; /* Adjust the font size as desired */
  }
  
  /* Timetable.css */

.timetable {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.timetable th,
.timetable td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.timetable th {
  background-color: #f2f2f2;
  color: #333;
}

.timetable tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .timetable {
    display: block;
    overflow-x: auto;
  }

  .timetable thead {
    display: none; /* Hide the table headers on small screens */
  }

  .timetable tr {
    display: block;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }

  .timetable td {
    display: block;
    text-align: right;
    font-size: 14px;
    border: none;
    position: relative;
    padding-left: 50%;
  }

  .timetable td::before {
    content: attr(data-label);
    position: absolute;
    left: 20px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    color: #555;
  }

  .timetable tr:last-child {
    border-bottom: 0;
  }
  .timetable td[data-label="Course"]::before {
    color: red;
  }
}
